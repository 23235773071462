<template>   
<dev class="body"> 
	<router-view v-slot="{ Component }" >
	   <keep-alive  >
	     <component :is="Component" :key="$route.fullPath" v-if="$route.meta.keepAlive"/>
	   </keep-alive>
	     <component :is="Component"  :key="$route.fullPath" v-if="!$route.meta.keepAlive"/>
	 </router-view> 
</dev>
</template>

<script >
import { reactive, ref, toRefs,onMounted,onUnmounted,nextTick,provide} from 'vue'  
import { useRoute, useRouter } from 'vue-router' 
import axios from '@/utils/axios' 
import { getLocalStorage,setLocalStorage,removeLocalStorage} from '@/utils'
  
export default {
  name: "App",
  setup()
  {
	 const noMenu = ['/Index']
	 const route = useRoute()
	 const router = useRouter()
     const isRouterAlive = ref(true);// 局部组件刷新 
     const react = reactive({
		  loading: false,
		  params:{
			   // tenantid:'4082601040254337024'
		  }
    }) 
	const routerBefore = router.beforeEach((to, from, next) => {   
		if (to.path == '/login') {
		     next()
		} 
		else 
		{ 
			  var loginoutput = getLocalStorage('loginoutput')  
			   if(loginoutput!=null){
				   next()
			   }
			   else{
					 router.push({ path: '/login'})  
				}
			   next() 
		}
	})
	onUnmounted(() => {
	  routerBefore()
	})
	 
	// // 局部组件刷新
	//  const reload = () => { 
	//       isRouterAlive.value = false; 
	//       nextTick(() => { 
	//          isRouterAlive.value = true; 
	//       });
	//     };
	//     provide("reload", reload);
	// // 局部组件刷新 end 
	 
	return {
	 ...toRefs(react), 
	   routerBefore,
	   isRouterAlive 
	}
  }
};
</script>


<style scoped>
	  
</style> 