 import { createRouter, createWebHistory } from "vue-router";
const routerHistory = createWebHistory();
// 定义一些路由,每个路由都需要映射到一个组件。
const routes = [  
	{
	     path: "/ActivitPageList",
	     name:"ActivitPageList",
	     component: () => import('../view/kybill/ActivitPageList'),
	     meta:{
	    	 keepAlive: false,
	     }
	 },
	 {
	      path: "/CustomersPageList",
	      name:"CustomersPageList",
	      component: () => import('../view/kybill/CustomersPageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	 {
	      path: "/Tenant_AccountPageList",
	      name:"Tenant_AccountPageList",
	      component: () => import('../view/kyauthority/Tenant_AccountPageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	 {
	      path: "/RolesPageList",
	      name:"RolesPageList",
	      component: () => import('../view/kyauthority/RolesPageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	 {
	      path: "/BranchesPageList",
	      name:"BranchesPageList",
	      component: () => import('../view/kyauthority/BranchesPageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	 {
	      path: "/Products_ClassPageList",
	      name:"Products_ClassPageList",
	      component: () => import('../view/kyproduct/Products_ClassPageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	 {
	      path: "/FilePageList",
	      name:"FilePageList",
	      component: () => import('../view/kyfile/FilePageList'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	  {
	       path: "/Products_SkuPageList",
	       name:"Products_SkuPageList",
	       component: () => import('../view/kyproduct/Products_SkuPageList.vue'),
	       meta:{
	      	 keepAlive: false,
	       }
	   },
	  {
	       path: "/ProductsPageList",
	       name:"ProductsPageList",
	       component: () => import('../view/kyproduct/ProductsPageList.vue'),
	       meta:{
	      	 keepAlive: false,
	       }
	   },
	  {
	       path: "/BillPageList",
	       name:"BillPageList",
	       component: () => import('../view/kybill/BillPageList.vue'),
	       meta:{
	      	 keepAlive: false,
	       }
	   },
	 {
	      path: "/BillAddEdit",
	      name:"BillAddEdit",
	      component: () => import('../view/kybill/BillAddEdit.vue'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	  {
	       path: "/Bill_PoolPageList",
	       name:"Bill_PoolPageList",
	       component: () => import('../view/kybill/Bill_PoolPageList.vue'),
	       meta:{
	      	 keepAlive: false,
	       }
	   },
	  {
			path: "/Cashier_PointPageList",
			name:"Cashier_PointPageList",
			component: () => import('../view/kybill/Cashier_PointPageList.vue'),
			meta:{
			 keepAlive: false,
			}
	    },
	{
	   path: "/TermView",
	   name: "TermView",
	   component: () => import('../view/TermView.vue'),
	   meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/Payment",
		name: "Payment",
		component: () => import('../view/Payment.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/OrderClass",
		name: "OrderClass",
		component: () => import('../view/OrderClass.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/OrderForGoods",
		name: "OrderForGoods",
		component: () => import('../view/OrderForGoods.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/ShoppingCart",
		name: "ShoppingCart",
		component: () => import('../view/ShoppingCart.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/MemberSattention",
		name: "MemberSattention",
		component: () => import('../view/MemberSattention.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/MemberChangePassword",
		name: "MemberChangePassword",
		component: () => import('../view/MemberChangePassword.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/login",
		name: "login",
		component: () => import('../view/login.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/MemberInformation",
		name: "MemberInformation",
		component: () => import('../view/MemberInformation.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/register",
		name: "register",
		component: () => import('../view/register.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/OrderFood",
		name: "OrderFood",
		component: () => import('../view/OrderFood.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/Home",
		name: "Home",
		component: () => import('../view/Home.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/memberHome",
		name: "memberHome",
		component: () => import('../view/memberHome.vue'),
		meta:{
		 keepAlive: false
	   }
	},
	{
		path: "/ProductDetail",
		name: "ProductDetail",
		component: () => import('../view/ProductDetail.vue'),
		meta:{
		 keepAlive: false
	   }  
	},
	{
		path: "/ProductAboveAndBelowView",
		name: "ProductAboveAndBelowView",
		component: () => import('../view/ProductAboveAndBelowView.vue'),
		meta:{
		 keepAlive: false
	   }  
	},
	{
		path: "/ProductTwoFiguresInOneLineView",
		name: "ProductTwoFiguresInOneLineView",
		component: () => import('../view/ProductTwoFiguresInOneLineView.vue'),
		meta:{
		 keepAlive: false
	   }  
	},
	{
		path: "/ProductNomaplistView",
		name: "ProductNomaplistView",
		component: () => import('../view/ProductNomaplistView.vue'),
		meta:{
		 keepAlive: false
	   }  
	},
	{
		path: "/DynamicDetail",
		name: "DynamicDetail",
		component: () => import('../view/DynamicDetail.vue'),
		meta:{
		 keepAlive: false
	   }  
	},
   {
		path: "/DynamicAboveAndBelowView",
		name: "DynamicAboveAndBelowView",
		component: () => import('../view/DynamicAboveAndBelowView.vue'),
		meta:{
		 keepAlive: false
	   } 
   },
   {
   		path: "/DynamicLeftPictureAndRightTextView",
   		name: "DynamicLeftPictureAndRightTextView",
   		component: () => import('../view/DynamicLeftPictureAndRightTextView.vue'),
   		meta:{
   		 keepAlive: false
   	   } 
   },
   {
   		path: "/ClassIficationView",
   		name: "ClassIficationView",
   		component: () => import('../view/ClassIficationView.vue') 
   },
   {
   		path: "/SinglepageView",
   		name: "SinglepageView",
   		component: () => import('../view/SinglepageView.vue'),
   		meta:{
   		 keepAlive: false
   	   }  
   },
   {
      path: "/Index",
      name: "Index",
      component: () => import('../view/Index.vue')
   },
 ]; 
const router = createRouter({
  history: routerHistory,
  routes,
});
//导出路由
export default router;
