import { getLocalStorage } from '@/utils'
import axios from 'axios'; 
import {showNotify} from 'vant';
import Router from '@/router/index'

axios.defaults.baseURL = '/api';
//请求头，headers 信息
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest' 
//默认 post 请求，使用 application/json 形式
axios.defaults.headers.post['Content-Type'] = 'application/json'
  
  //添加请求拦截器
  axios.interceptors.request.use(
  	config=>{ 
  		 // console.log("config1 "+JSON.stringify(config));
  			var loginoutput = getLocalStorage('loginoutput')
  			 if(loginoutput!=null)
  			 { 
  				 if(loginoutput.success==true)
  				 {
  					 if(loginoutput.jwtToken!=null)
  					 {
  						 if(loginoutput.jwtToken.status==true)
  						 {
  							 //console.log("config "+JSON.stringify(config));
  							 // 在发送请求之前做些什么
  							 // 判断是否存在token,如果存在将每个页面header添加token
  							 config.headers.Authorization = loginoutput.jwtToken.tokenType+" "+loginoutput.jwtToken.token
  							 //config.headers.common['Authorization'] = loginoutput.jwtToken.tokenType+" "+loginoutput.jwtToken.token
  							 
  						 }
  					 } 
  				 }
  			 }  
  			return config
  		},
  		err=>{  
  			  console.log(5555);
  			 // return Promise.reject(err);
  		}
  ) 
  
    
  //添加响应拦截器,可以访问webapi才响应
  axios.interceptors.response.use(  
      response=>{
          return response
      },
      err=>{  
		  console.log("err:"+err.request); 
  		if(err!=null && err.request!=null && err.request.status!=null && err.request.status == 401)
  		{
  			if( err.request.statusText == 'Unauthorized')
  			{     
  				  Router.push({ path: '/login'}) 
  			}
  		}
  		else{
  			var now = new Date();
  			var nowparse = Date.parse(now); 
  			var expires = getLocalStorage('expires') 
  			if(expires!=null)
  			{
  				 //console.log(666); 
  				 if (nowparse>= expires) 
  				 { 
  					  Router.push({ path: '/login'})
  				 }  
  			} 
  		}
  		if(err!=null && err.request!=null && err.request.status!=null && err.request.status == 403&&err.request.statusText == "Forbidden")
  		{
  			// console.log(666); 
  			 showNotify.warning("无此权限") 
  		}
         return Promise.reject(err);
      }
  )  
export default axios